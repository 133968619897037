// --- base styles ---


@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto Regular'), local('Roboto-Regular'),
         url('../assets/fonts/Roboto-Regular.woff') format('woff'), /* WOFF for Roboto Regular */
         url('../assets/fonts/Roboto-Regular.ttf') format('truetype'); /* TTF for Roboto Regular */
    font-display: optional;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'),
         url('../assets/fonts/Roboto-Medium.woff') format('woff'), /* WOFF for Roboto Medium */
         url('../assets/fonts/Roboto-Medium.ttf') format('truetype'); /* TTF for Roboto Medium */
    font-display: optional;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto Bold'), local('Roboto-Bold'),
         url('../assets/fonts/Roboto-Bold.woff') format('woff'), /* WOFF for Roboto Bold */
         url('../assets/fonts/Roboto-Bold.ttf') format('truetype'); /* TTF for Roboto Bold */
    font-display: optional;
}

@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 500;
    src: local('Sora Medium'), local('Sora-Medium'),
         url('../assets/fonts/Sora-Medium.woff') format('woff'), /* WOFF for Sora Medium */
         url('../assets/fonts/Sora-Medium.ttf') format('truetype'); /* TTF for Sora Medium */
    font-display: optional;
}

@font-face {
    font-family: 'Sora';
    font-style: normal;
    font-weight: 600;
    src: local('Sora SemiBold'), local('Sora-SemiBold'),
         url('../assets/fonts/Sora-SemiBold.woff') format('woff'), /* WOFF for Sora SemiBold */
         url('../assets/fonts/Sora-SemiBold.ttf') format('truetype'); /* TTF for Sora SemiBold */
    font-display: optional;
}



* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
}

html,
body {
    overflow-x: hidden;
    min-height: 100vh;
}

ul,
ol {
    list-style: none;
}

a {
    text-decoration: none;
}

a,
input,
button,
textarea {
    background: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: none;
    cursor: pointer;
}

// iOS fix
button[type="submit"] {
    font-weight: 400 !important;
}

input,
textarea {
    &[readonly] {
        cursor: default;
    }

    &::placeholder {
        transition: opacity var(--transition);
    }

    &:focus {
        &::placeholder {
            opacity: 0;
        }
    }
}

textarea {
    resize: none;
}

fieldset {
    border: none;
}

label {
    cursor: pointer;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: auto;
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

// google maps

a[href^="http://maps.google.com/maps"] {
    display: none !important
}

a[href^="https://maps.google.com/maps"] {
    display: none !important
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
    display: none;
}

.gmnoprint div {
    background: none !important;
}